export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138'),
	() => import('./nodes/139'),
	() => import('./nodes/140'),
	() => import('./nodes/141'),
	() => import('./nodes/142'),
	() => import('./nodes/143'),
	() => import('./nodes/144'),
	() => import('./nodes/145'),
	() => import('./nodes/146'),
	() => import('./nodes/147'),
	() => import('./nodes/148'),
	() => import('./nodes/149')
];

export const server_loads = [];

export const dictionary = {
		"/": [27],
		"/auth/changepwd": [28,[2]],
		"/auth/company-selector": [29,[2]],
		"/auth/landing": [30,[2]],
		"/auth/loading": [31,[2]],
		"/auth/login-failure": [33,[2]],
		"/auth/login": [32,[2]],
		"/auth/magic-link": [34,[2]],
		"/auth/password": [35,[2]],
		"/banking/accountReplacement": [36,[3]],
		"/banking/accounts": [37,[3]],
		"/banking/cards": [38,[3]],
		"/banking/directDeposit": [39,[3]],
		"/banking/edit": [40,[3]],
		"/banking/elections": [41,[3,4]],
		"/banking/paycard": [42,[3]],
		"/budget": [43],
		"/budget/add": [44],
		"/budget/edit": [45],
		"/budget/intro": [46],
		"/debit-card/activate/complete": [47,[5]],
		"/debit-card/activate/validate": [48,[5]],
		"/debit-card/change-pin/complete": [49,[6]],
		"/debit-card/change-pin/validate": [50,[6]],
		"/debit-card/statements/list": [51,[7]],
		"/debit-card/statements/view/[date]": [52,[7]],
		"/disabled": [53],
		"/enrollment-legion/connectBank": [98,[14]],
		"/enrollment-legion/connectCard": [99,[14,15]],
		"/enrollment-legion/directDeposit": [100,[14]],
		"/enrollment-legion/getting-start": [101,[14]],
		"/enrollment-legion/introduction/intro1": [102,[14,16]],
		"/enrollment-legion/introduction/intro2": [103,[14,16]],
		"/enrollment-legion/introduction/intro3": [104,[14,16]],
		"/enrollment-legion/progressLegion": [105,[14]],
		"/enrollment-legion/splash": [106,[14]],
		"/enrollment-legion/tos": [107,[14]],
		"/enrollment/authorizeACH": [54,[8]],
		"/enrollment/connectBank": [55,[8]],
		"/enrollment/connectCard": [56,[8]],
		"/enrollment/connectPayroll": [57,[8]],
		"/enrollment/debitCard/collect/address": [58,[8]],
		"/enrollment/debitCard/collect/create": [59,[8]],
		"/enrollment/debitCard/collect/dobssn": [60,[8]],
		"/enrollment/debitCard/collect/namedobssn": [61,[8]],
		"/enrollment/debitCard/collect/review": [62,[8]],
		"/enrollment/debitCard/final/banking-pending": [63,[8,9]],
		"/enrollment/debitCard/final/kyc-failed-address": [64,[8,9]],
		"/enrollment/debitCard/final/kyc-failed-id": [65,[8,9]],
		"/enrollment/debitCard/final/pending": [66,[8,9]],
		"/enrollment/debitCard/final/update-address": [67,[8,9]],
		"/enrollment/debitCard/final/user-not-approved": [68,[8,9]],
		"/enrollment/debitCard/intro": [69,[8]],
		"/enrollment/directDeposit": [70,[8]],
		"/enrollment/getting-start": [71,[8]],
		"/enrollment/harri/connectBankAccount": [72,[8]],
		"/enrollment/harri/connectCard": [73,[8,10]],
		"/enrollment/harri/connectPayroll": [74,[8]],
		"/enrollment/harri/progress": [75,[8]],
		"/enrollment/harri/splash": [76,[8]],
		"/enrollment/introduction/intro1": [77,[8,11]],
		"/enrollment/introduction/intro2": [78,[8,11]],
		"/enrollment/introduction/intro3": [79,[8,11]],
		"/enrollment/link-payroll/step1": [80,[8,12]],
		"/enrollment/link-payroll/step2": [81,[8,12]],
		"/enrollment/link-payroll/step3": [82,[8,12]],
		"/enrollment/osvIntros/end": [83,[8,13]],
		"/enrollment/osvIntros/intro1": [84,[8,13]],
		"/enrollment/osvIntros/intro2": [85,[8,13]],
		"/enrollment/osvIntros/intro3": [86,[8,13]],
		"/enrollment/osvSplash": [87,[8]],
		"/enrollment/osvWelcome": [88,[8]],
		"/enrollment/partialEnroll/explanation": [89,[8]],
		"/enrollment/partialEnroll/intro": [90,[8]],
		"/enrollment/progress": [91,[8]],
		"/enrollment/selectDistrictTOS": [93,[8]],
		"/enrollment/selectDistrict": [92,[8]],
		"/enrollment/splash": [94,[8]],
		"/enrollment/tos": [95,[8]],
		"/enrollment/verifyIncome": [96,[8]],
		"/enrollment/welcome-intro": [97,[8]],
		"/ewa/balance/earning": [108,[17,18]],
		"/ewa/balance/spending": [109,[17,18]],
		"/ewa/debitcard_detail": [110,[17]],
		"/ewa/detail": [111,[17]],
		"/ewa/onboarding": [112,[17]],
		"/ewa/paymentElectionNotice": [113,[17]],
		"/ewa/transfer/success": [114,[17]],
		"/help-center/[slug]": [115],
		"/onboarding/company-selector": [116,[19]],
		"/onboarding/login": [117,[19]],
		"/onboarding/magic-link": [118,[19]],
		"/onboarding/password": [119,[19]],
		"/onboarding/welcome": [120,[19]],
		"/savings": [121,[20]],
		"/savings/dashboard": [122,[20]],
		"/savings/dashboard/edit": [123,[20]],
		"/savings/onboarding/collect/create": [124,[20,21]],
		"/savings/onboarding/collect/page1": [125,[20,21]],
		"/savings/onboarding/collect/page2": [126,[20,21]],
		"/savings/onboarding/collect/page3": [127,[20,21]],
		"/savings/onboarding/final/confirm-account-details": [128,[20,22]],
		"/savings/onboarding/final/kyc-failed": [129,[20,22]],
		"/savings/onboarding/final/no-tp-account": [130,[20,22]],
		"/savings/onboarding/final/user-approved": [131,[20,22]],
		"/savings/onboarding/final/user-not-approved": [132,[20,22]],
		"/savings/onboarding/intro2": [134,[20]],
		"/savings/onboarding/intro": [133,[20]],
		"/savings/onboarding/notice": [135,[20]],
		"/savings/transfer": [136,[20]],
		"/session-expired": [137],
		"/settings": [138,[23]],
		"/settings/change-password": [139,[23]],
		"/settings/change-phone-number/complete": [140,[23]],
		"/settings/change-phone-number/validate": [141,[23]],
		"/settings/debit-card-controls": [142,[23]],
		"/settings/personal-details": [143,[23]],
		"/settings/stream-controls": [144,[23]],
		"/track/history": [145,[24,25]],
		"/track/shifts": [146,[24,26]],
		"/track/statements": [147,[24]],
		"/track/transfers": [148,[24]],
		"/updating": [149]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';